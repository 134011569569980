import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import {store} from './store/store'
import print from 'vue3-print-nb'

const app = createApp(App)
app.use(store)
app.use(print)

app.mount('#app')